<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="8">
                    <el-input placeholder="请输入教室名称" v-model="QueryInfo.Name" clearable @clear="QueryClassRoomsList"> 
                        <el-button slot="append" icon="el-icon-search" @click="QueryClassRoomsList"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="ShowAddClassRoomDialog">新建教室</el-button>
                </el-col>
            </el-row>
            <el-table :data="ClassRoomlist" v-loading="Loading" @row-click="ShowModifyClassRoomDialog" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="描述" prop="description"></el-table-column> 
                <el-table-column label="学生数" prop="maxCount"></el-table-column> 
                <el-table-column label="操作" prop="option" width="300">
                    <template slot-scope="scope"> 
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" @click.stop="DeleteClassRoomDialog(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="ClassRoomTotal">
            </el-pagination>
        </el-card>
        <el-dialog title="新建教室" :visible.sync="AddClassRoomDialogVisible" width="30%"  @close="AddClassRoomDialogClosed" :close-on-click-modal="false">
            <el-form class="form_div" v-loading="AdLoading" :model="AddClassRoomForm" :rules="AddClassRoomFormRules" ref="AddClassRoomFormRulesRef"  label-width="100px">
                <el-form-item label="名称：" prop="Name">
                    <el-input v-model="AddClassRoomForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="AddClassRoomForm.Description"></el-input>
                </el-form-item>
                <el-form-item label="学生数：" prop="MaxCount">
                    <el-input-number v-model="AddClassRoomForm.MaxCount" :precision="0" :step="1" :min="0" :max="50"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="AddClassRoomDialogVisible = false" :disabled="AdLoading">取消</el-button>
                <el-button type="primary" @click="AddClassRoom" :disabled="AdLoading">确定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑教室" :visible.sync="EditClassRoomDialogVisible" width="30%"  @close="EditClassRoomDialogClosed" :close-on-click-modal="false">
            <el-form class="form_div" v-loading="EdLoading" :model="EditClassRoomForm" :rules="EditClassRoomFormRules" ref="EditClassRoomFormRulesRef"  label-width="100px">
                <el-form-item label="名称：" prop="Name">
                    <el-input v-model="EditClassRoomForm.Name"></el-input>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="EditClassRoomForm.Description"></el-input>
                </el-form-item>
                <el-form-item label="学生数：" prop="MaxCount">
                    <el-input-number v-model="EditClassRoomForm.MaxCount" :precision="0" :step="1" :min="0" :max="50"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer">
                <el-button @click="EditClassRoomDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="EditClassRoom" :disabled="EdLoading">确定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
import { GetQueryClassRoomsList, PostClassRoom, PutClassRoom, DeleteClassRoom } from '@/assets/js/api/classroomapi';
import { SpreadCount } from '@/assets/js/systemvariable.js';
export default {
    data(){
        return{
            spreadCount:SpreadCount,
            ClassRoomlist:[],
            QueryInfo:{
                Name:'',
                Start:1,
                Count:SpreadCount[0]
            },
            ClassRoomTotal:0,
            AddClassRoomDialogVisible:false,
            AddClassRoomForm:{
                Name:'',
                Description:'',
                MaxCount:0
            },
            AddClassRoomFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                Description: [
                    { required: true, message: '请输入描述', trigger: 'blur' }
                ],
                MaxCount: [
                    { required: true, message: '请输入学生数', trigger: 'blur' }
                ]
            },
            EditClassRoomDialogVisible:false,
            EditClassRoomForm:{
                id:0,
                Name:'',
                Description:'',
                MaxCount:0
            },
            EditClassRoomFormRules:{
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                Description: [
                    { required: true, message: '请输入描述', trigger: 'blur' }
                ],
                MaxCount: [
                    { required: true, message: '请输入学生数', trigger: 'blur' }
                ]
            },
            Loading:false,
            AdLoading:false,
            EdLoading:false,
            OriginalData:{},
        }
    },
    created(){
        this.GetClassRoomsList()
    },
    methods:{
        ShowAddClassRoomDialog(){
            this.AddClassRoomDialogVisible = true
        },
        QueryClassRoomsList(){
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.GetClassRoomsList()
        },
        async GetClassRoomsList(){
            this.Loading = true
            var ret = await GetQueryClassRoomsList(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.ClassRoomlist = ret.data.classRooms
                this.ClassRoomTotal = ret.data.total
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        ShowModifyClassRoomDialog(classroom){
            this.EditClassRoomForm.id = classroom.id
            this.EditClassRoomForm.Name = classroom.name
            this.EditClassRoomForm.Description = classroom.description
            this.EditClassRoomForm.MaxCount = classroom.maxCount
            this.OriginalData = classroom
            this.EditClassRoomDialogVisible = true
        },
        async DeleteClassRoomDialog(classroom){
            const confirmResult = await this.$confirm('此操作将永远删除该教室, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            this.Loading = true
            var ret = await DeleteClassRoom(classroom.id)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除教室成功')
                this.GetClassRoomsList()
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleSizeChange(Count){
            this.QueryInfo.Count = Count
            this.GetClassRoomsList()
        },
        HandleCurrentChange(Start){
            this.QueryInfo.Start = Start
            this.GetClassRoomsList()
        },
        AddClassRoomDialogClosed(){
            this.$refs.AddClassRoomFormRulesRef.resetFields()
        },
        EditClassRoomDialogClosed(){
            this.$refs.EditClassRoomFormRulesRef.resetFields()
        },
        async AddClassRoom(){
            this.$refs.AddClassRoomFormRulesRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            this.AdLoading = true
            var ret = await PostClassRoom(this.AddClassRoomForm)
            this.AdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('添加教室成功')
                this.GetClassRoomsList()
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.AddClassRoomDialogVisible = false
        },
        async EditClassRoom(){
            if(this.EditClassRoomForm.Name == this.OriginalData.name && 
            this.EditClassRoomForm.Description == this.OriginalData.description && 
            this.EditClassRoomForm.MaxCount == this.OriginalData.maxCount){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.$refs.EditClassRoomFormRulesRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            } 
            this.EdLoading = true
            var ret = await PutClassRoom(this.EditClassRoomForm)
            this.EdLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('编辑教室成功')
                this.GetClassRoomsList()
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.EditClassRoomDialogVisible = false
        }
    }
}
</script>

<style scoped lang="less">
    .form_div{
        text-align: left;
    }
</style>