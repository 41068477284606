import axios from 'axios';


//获取所有教室
export async function GetAllClassRoomList(){
    var res = await axios.get('ClassRoom/v1/AllClassRooms')
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

//获取教室列表
export async function GetQueryClassRoomsList(queryinfo){
    var res = await axios.get('ClassRoom/v1/ClassRooms', {params : queryinfo})
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }

//提交新建教室
export async function PostClassRoom(ClassRoom){
  var res = await axios.post('ClassRoom/v1/ClassRoom', ClassRoom)
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//修改教室
export async function PutClassRoom(ClassRoom){
  var res = await axios.put('ClassRoom/v1/ClassRoom', ClassRoom)
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//删除教室
export async function DeleteClassRoom(id){
  var res = await axios.delete('ClassRoom/v1/ClassRoom',{ params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}
